<script>
export default {
  name: 'GuestLayout',
  beforeCreate() {
    this.$nextTick()
      .then(() => document.body.classList.add('emre'));
  },
};
</script>

<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <div class="text-center mb-4">
        <a href="/" class="navbar-brand navbar-brand-autodark">
          <img src="@/assets/static/logo.svg" width="110" height="32" alt="Tabler"
               class="navbar-brand-image">
        </a>
      </div>
      <div class="card card-md">
        <slot/>
      </div>
    </div>
  </div>
</template>
