import GuestLayout from '@/theme/layouts/GuestLayout.vue';
import LoggedLayout from '@/theme/layouts/LoggedLayout.vue';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Home/LandingPage.vue'),
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'Home Page',
      pageLayout: LoggedLayout,
    },
  },
  /* Wallet Routes */
  {
    path: '/wallets',
    name: 'wallets',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'wallet.index',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/Wallet/WalletList.vue'),
  },
  {
    path: '/wallets/create',
    name: 'wallet.create',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'wallet.create',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/Wallet/WalletStore.vue'),
  },
  {
    path: '/wallets/:wallet',
    name: 'wallet.detail',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'wallet.details',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/Wallet/WalletShow.vue'),
  },
  /* Entity Routes */
  {
    path: '/entities',
    name: 'entities',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'entity.index',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/EntityList.vue'),
  },
  {
    path: '/entities/create',
    name: 'entity.create',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'entity.create',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/EntityStore.vue'),
  },
  {
    path: '/entities/:entity',
    name: 'entity.detail',
    meta: {
      isAuth: true,
      fetchMe: true,
      pageTitle: 'entity.details',
      pageLayout: LoggedLayout,
    },
    component: () => import('@/views/Entity/EntityShow.vue'),
  },
  /* Auth Routes */
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth/SigIn.vue'),
    meta: {
      isAuth: false,
      fetchMe: false,
      pageTitle: 'Login Page',
      pageLayout: GuestLayout,
    },
  },
];
