import { createWebHistory, createRouter } from 'vue-router';
import { checkAccessMiddleware, getLoggedUserMiddleware } from './middleware';
import routes from './routes';

const router = createRouter({
  mode: 'history',
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 };
  },
});
router.beforeEach(checkAccessMiddleware);
router.beforeResolve(getLoggedUserMiddleware);

export default router;
