const field = {
  state: {
    wallets: [
      {
        text: 'TRX',
        value: '1',
      },
      {
        text: 'USDT',
        value: '2',
      },
    ],
  },
  namespaced: true,
};

export default field;
