<script>
import { mapState } from 'vuex';
import { useToast } from 'vue-toast-notification';

export default {
  computed: {
    ...mapState({
      loader: (state) => state.base.loader,
      message: (state) => state.base.message,
    }),
  },
  watch: {
    message: {
      deep: true,
      handler(val) {
        if (val.type === 'error') {
          this.t$.error(this.$t(val.message));
        }

        if (val.type === 'success') {
          this.t$.success(this.$t(val.message));
        }
      },
    },
  },
  setup() {
    return {
      t$: useToast(),
    };
  },
};
</script>
<template>
  <component :is="$route.meta.pageLayout">
    <router-view :key="$route.fullPath"/>
  </component>
</template>

<style scoped lang="scss">
</style>
