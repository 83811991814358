import cookies from 'js-cookie';

export function getToken() {
  return cookies.get('_token') || null;
}
export function setToken(token) {
  return cookies.set('_token', token);
}

export function removeToken(token) {
  return cookies.remove(token);
}
