import { login, profile } from '@/api/auth';
import { getToken, setToken } from '@/utils/cookie';

const auth = {
  state: {
    user: null,
    token: getToken(),
  },
  getters: {
    user: (state) => state.user,
    isLoggedIn: (state) => !!state.token,
  },
  mutations: {
    user: (state, payload) => {
      state.user = payload;
    },
    token: (state, token) => {
      state.token = token;
      setToken(token);
    },
  },
  actions: {
    login({ commit }, payload) {
      return login(payload).then((response) => {
        if (response) {
          commit('token', response.data.token);
        }
        return response;
      }).catch((err) => {
        throw err;
      });
    },
    profile({ commit }) {
      return profile().then((response) => {
        if (response) {
          commit('user', response.data);
        }
        return response;
      }).catch((err) => {
        throw err;
      });
    },
  },
  namespaced: true,
};

export default auth;
