import service from '@/utils/request';

export async function login(payload) {
  return service.post('/auth/login', {
    ...payload,
  }, {});
}

export async function profile() {
  return service.get('/users/profile');
}
