<script>
import { mapState } from 'vuex';

export default {
  name: 'LoggedLayout',
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      loader: (state) => state.base.loader,
      message: (state) => state.base.message,
    }),
    avatar() {
      return {
        backgroundImage: `url('${this.user.attributes.avatar}')`,
      };
    },
  },
};
</script>

<template>
  <div class="page">
    <header class="navbar navbar-expand-md d-print-none">
      <div class="container-xl">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
          <a href=".">
            <img src="@/assets/static/logo.svg" width="110" height="32" alt="Tabler"
                 class="navbar-brand-image">
          </a>
        </h1>
        <div class="navbar-nav flex-row order-md-last">
          <div class="d-none d-md-flex">
            <a href="?theme=dark" class="nav-link px-0 hide-theme-dark" title="Enable dark mode"
               data-bs-toggle="tooltip"
               data-bs-placement="bottom">
              <i class="ti ti-moon"></i>
            </a>
            <a href="?theme=light" class="nav-link px-0 hide-theme-light" title="Enable light mode"
               data-bs-toggle="tooltip"
               data-bs-placement="bottom">
              <i class="ti ti-sun"></i>
            </a>
            <div class="nav-item dropdown d-none d-md-flex me-3">
              <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1"
                 aria-label="Show notifications">
                <i class="ti ti-bell"></i>
                <span class="badge bg-red"></span>
              </a>
              <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Last updates</h3>
                  </div>
                  <div class="list-group list-group-flush list-group-hoverable">
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col-auto"><span
                          class="status-dot status-dot-animated bg-red d-block"></span></div>
                        <div class="col text-truncate">
                          <a href="#" class="text-body d-block">Example 1</a>
                          <div class="d-block text-secondary text-truncate mt-n1">
                            Change deprecated html tags to text decoration classes (#29604)
                          </div>
                        </div>
                        <div class="col-auto">
                          <a href="#" class="list-group-item-actions">
                            <i class="ti ti-star"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nav-item dropdown">
            <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
               aria-label="Open user menu">
              <span class="avatar avatar-sm" :style="avatar"></span>
              <div class="d-none d-xl-block ps-2">
                <div>
                  {{ user.attributes.name }}
                </div>
                <div class="mt-1 small text-secondary">
                  {{ user.attributes.email }}
                </div>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <a href="#" class="dropdown-item">Status</a>
              <a href="./profile.html" class="dropdown-item">Profile</a>
              <a href="#" class="dropdown-item">Feedback</a>
              <div class="dropdown-divider"></div>
              <a href="./settings.html" class="dropdown-item">Settings</a>
              <a href="./sign-in.html" class="dropdown-item">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="navbar-expand-md">
      <div class="collapse navbar-collapse" id="navbar-menu">
        <div class="navbar">
          <div class="container-xl">
            <ul class="navbar-nav">
              <li class="nav-item">
                <RouterLink
                  :to="{
                    name: 'dashboard'
                  }"
                  class="nav-link"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <i class="ti ti-home icon"></i>
                  </span>
                  <span class="nav-link-title">
                    {{ $t('menu.item.home') }}
                  </span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink
                  :to="{
                    name: 'wallets'
                  }"
                  class="nav-link"
                  >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                      <i class="ti ti-wallet icon"></i>
                    </span>
                    <span class="nav-link-title">
                      {{ $t('menu.item.wallets') }}
                    </span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink
                  :to="{
                    name: 'entities'
                  }"
                  class="nav-link"
                >
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <i class="ti ti-wallet icon"></i>
                  </span>
                  <span class="nav-link-title">
                    {{ $t('menu.item.entities') }}
                  </span>
                </RouterLink>
              </li>
            </ul>
            <div class="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
              <form action="./" method="get" autocomplete="off" novalidate>
                <div class="input-icon">
                    <span class="input-icon-addon">
                      <i class="ti ti-search"></i>
                    </span>
                  <input type="text" value="" class="form-control" placeholder="Search…"
                         aria-label="Search in website">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
    <slot/>
    <footer class="footer footer-transparent d-print-none">
      <div class="container-xl">
        <div class="row text-center align-items-center flex-row-reverse">
          <div class="col-lg-auto ms-lg-auto">
            <ul class="list-inline list-inline-dots mb-0">
              <li class="list-inline-item">
                <a href="https://github.com/sponsors/codecalm" target="_blank"
                   class="link-secondary" rel="noopener">
                  <i class="ti ti-heart"></i>
                  Sponsor {{ loader }} - {{ message }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul class="list-inline list-inline-dots mb-0">
              <li class="list-inline-item">
                Copyright &copy; 2023
                <a href="." class="link-secondary">Tabler</a>.
                All rights reserved.
              </li>
              <li class="list-inline-item">
                <a href="./changelog.html" class="link-secondary" rel="noopener">
                  v1.0.0-beta20
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
