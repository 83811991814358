import { createStore } from 'vuex';

import auth from './modules/auth';
import base from './modules/base';
import field from './modules/field';

const store = createStore({
  modules: {
    auth,
    base,
    field,
  },
  strict: false,
});

export default store;
