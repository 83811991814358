import axios from 'axios';
import store from '@/store';
import { getToken } from '@/utils/cookie';
import router from '@/router';

const API_URL = process.env.VUE_APP_API_URL;

if (!API_URL) {
  throw new Error('VUE_APP_API_URL environment variable is not set');
}

const service = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  async (config) => {
    try {
      const token = getToken();

      // Create a new config object without mutating the original config parameter
      const newConfig = { ...config };

      if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }

      return newConfig;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Request Interceptor Error:', error);
      return false;
    }
  },
  (error) => {
    store.commit('base/loader', false);
    store.commit('base/message', error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 201) {
      store.commit('base/message', {
        type: 'success',
        message: 'GLOBAL_CREATED',
      });
    }

    if (response.status === 202) {
      store.commit('base/message', {
        type: 'success',
        message: 'GLOBAL_UPDATED',
      });
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 404) {
        router.push({ name: 'dashboard' });
      }

      if (error.response.status === 422) {
        store.commit('base/message', {
          type: 'error',
          message: error.response.data.message,
        });
      }

      if (error.response.status === 500) {
        store.commit('base/message', {
          type: 'error',
          message: 'GLOBAL_ERROR',
        });
      }
    }
    return false;
  },
);

export default service;
