const base = {
  state: {
    loader: false,
    message: null,
  },
  getters: {
    loader: (state) => state.loader,
    message: (state) => state.message,
  },
  mutations: {
    loader: (state, payload) => {
      state.loader = payload;
    },
    message: (state, payload) => {
      state.message = payload;
    },
  },
  actions: {
    loader({ commit }, payload) {
      commit('loader', payload);
    },
    message({ commit }, payload) {
      commit('message', payload);
    },
  },
  namespaced: true,
};

export default base;
