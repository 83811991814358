import { createApp } from 'vue';

import store from '@/store';
import router from '@/router';
import toaster from 'vue-toast-notification';
import language from './i18n';

import App from './App.vue';
import '@/assets/webfont/tabler-icons.scss';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(toaster);
app.use(language);
app.mount('#app');
