import store from '@/store/index';
import { getToken } from '@/utils/cookie';

export function checkAccessMiddleware(to, from, next) {
  if (to.matched.some((record) => record.meta.isAuth)) {
    if (!getToken()) {
      next({
        name: 'auth',
      });
    } else {
      next();
    }
  } else {
    next();
  }
}

export async function getLoggedUserMiddleware(to) {
  const isAuth = to.matched.some((record) => record.meta.isAuth);
  const fetchMe = to.matched.some((record) => record.meta.fetchMe);

  if (getToken()) {
    if (isAuth || fetchMe) {
      await store.dispatch('auth/profile');
    }
  }
}
